import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import './styles/index.scss'
import './styles/index1.scss'
import 'font-awesome/css/font-awesome.min.css';
import Toast  from 'vant';
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);
Vue.use(Toast);
Vue.use(Vant);
import { Lazyload } from 'vant';
Vue.use(Lazyload);

import {Base64} from 'js-base64'
Vue.prototype.$Base64 = Base64;
import { decryptData,decryptDemo } from './utils/cryptoUtils';  // 引入解密函数
Vue.prototype.$decryptData = decryptData;
Vue.prototype.$decryptDemo = decryptDemo;
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.config.productionTip = false


new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
