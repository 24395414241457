import CryptoJS from 'crypto-js';

export function decryptData(encryptedData, key='', iv='') {
  // 设置默认的密钥和初始化向量，应该与后端相同
  const parsedKey = CryptoJS.enc.Utf8.parse( 'MSIQkhJG40vrA0TUHj7XkD5QQ1VwgJfY');  // 使用默认密钥
  const parsedIv = CryptoJS.enc.Utf8.parse( '5fde6ms0a147a904');    // 使用默认IV

  // 将加密数据从 base64 编码解析成 CryptoJS 的密文格式
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedData);
  // 使用 AES 解密
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    parsedKey,
    { iv: parsedIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  // 返回解密后的 UTF-8 字符串
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  // 如果解密结果为空，说明可能是非 UTF-8 编码的内容，处理异常
  if (!decryptedText) {
    console.error('Decryption failed. Check the encryption parameters.');
    return '';
  }

  return decryptedText;
}



export function decryptDemo(encryptedData, key='', iv='') {
  // 设置默认的密钥和初始化向量，应该与后端相同
  const parsedKey = CryptoJS.enc.Utf8.parse( 'bf08ea80c334611a');  // 使用默认密钥
  const parsedIv = CryptoJS.enc.Utf8.parse( '5fde6ad0a147a904');    // 使用默认IV

  // 将加密数据从 base64 编码解析成 CryptoJS 的密文格式
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedData);
  // 使用 AES 解密
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    parsedKey,
    { iv: parsedIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  // 返回解密后的 UTF-8 字符串
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  // 如果解密结果为空，说明可能是非 UTF-8 编码的内容，处理异常
  if (!decryptedText) {
    console.error('Decryption failed. Check the encryption parameters.');
    return '';
  }

  return decryptedText;
}
