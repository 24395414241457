// 图片加载工具类
class ImageLoader {
    constructor(maxRetries = 3, retryDelay = 1000) {
      this.maxRetries = maxRetries;
      this.retryDelay = retryDelay;
      this.loadingImages = new Set(); // 追踪正在加载的图片
    }
  
    async loadImage(imageId, url, retryCount = 0) {
      // 如果已经在加载中，直接返回
      if (this.loadingImages.has(imageId)) {
        return;
      }
  
      this.loadingImages.add(imageId);
  
      try {
        // 尝试不同的图片扩展名
        const extensions = ['_.jpg'];
        let success = false;
  
        for (const ext of extensions) {
          if (success) break;
          
          const fullUrl = url.endsWith(ext) ? url : url + ext;
          
          try {
            const response = await this.fetchWithTimeout(fullUrl, 5000);
            
            if (!response.ok) {
              continue;
            }
  
            const blob = await response.blob();
            if (blob.size === 0) {
              continue;
            }
  
            await this.processAndDisplayImage(blob, imageId);
            success = true;
            
          } catch (error) {
            console.warn(`Failed to load image with extension ${ext}:`, error);
            continue;
          }
        }
  
        if (!success && retryCount < this.maxRetries) {
          // 延迟重试
          await new Promise(resolve => setTimeout(resolve, this.retryDelay));
          return this.loadImage(imageId, url, retryCount + 1);
        }
  
        if (!success) {
          // 所有重试都失败后，显示默认图片
          this.displayFallbackImage(imageId);
        }
  
      } catch (error) {
        console.error('Image loading failed:', error);
        if (retryCount < this.maxRetries) {
          await new Promise(resolve => setTimeout(resolve, this.retryDelay));
          return this.loadImage(imageId, url, retryCount + 1);
        } else {
          this.displayFallbackImage(imageId);
        }
      } finally {
        this.loadingImages.delete(imageId);
      }
    }
  
    async fetchWithTimeout(url, timeout = 5000) {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);
  
      try {
        const response = await fetch(url, {
          signal: controller.signal
        });
        clearTimeout(timeoutId);
        return response;
      } catch (error) {
        clearTimeout(timeoutId);
        throw error;
      }
    }
  
    async processAndDisplayImage(blob, imageId) {
      // 移除前3个字节
      const arrayBuffer = await blob.arrayBuffer();
      const processedBlob = new Blob([arrayBuffer.slice(3)], { type: 'image/jpeg' });
      
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imgElement = document.getElementById(imageId);
          if (imgElement) {
            imgElement.src = e.target.result;
            resolve();
          } else {
            console.log('tupian ID not found：'+imageId);
            reject(new Error('Image element not found'));
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(processedBlob);
      });
    }
  
    displayFallbackImage(imageId) {
      const imgElement = document.getElementById(imageId);
      if (imgElement) {
        imgElement.src = 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"%3E%3Crect width="100" height="100" fill="%23f0f0f0"/%3E%3Ctext x="50" y="50" font-family="Arial" font-size="14" fill="%23999" text-anchor="middle" dy=".3em"%3E加载失败%3C/text%3E%3C/svg%3E';
      }
    }
  }
  
  // 使用示例
  export const imageLoader = new ImageLoader();
  
 