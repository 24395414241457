
<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },

  mounted() {
    if (!navigator.userAgent.match(/(googlebot|bingbot|baiduspider)/i)) {
      // 不是爬虫时执行的逻辑
      console.log("正常访问");
    } else {
      // 是爬虫时的逻辑
      window.location.href = "http://www.qq.com/babygohome/?pgv_ref=404";
      console.log("禁止爬虫");
    }
  },
  methods: {
 
  },
};
</script>

<style lang="scss">
.code-box-back{
  background: #f5f5f5 !important;
  
}
</style>
