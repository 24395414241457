import request from './request'

//v6版本

export function iv6Api(data) {
  return request.get(`nb/iv7`,data);
}



export function vv6Api(data){
  return request.get(`nb/vv6`,data);
}
/**
 * 分类数据
 * @param {*} data 
 * @returns 
 */
export function nc6Api(data) {
  return request.get(`nb/nc6`,data);
}
/**
 * 视频列表
 * @param {*} data 
 * @returns 
 */
export function vlv6Api(data) {
  return request.get(`nb/vlv6`,data);
}
/**
 * 已支付列表
 * @param {*} data 
 * @returns 
 */
export function plyv6Api(data) {
  return request.get(`nb/plyv6`,data);
}
/**
 * 视频详情
 * @param {*} data 
 * @returns 
 */
export function dv6Api(data) {
  return request.get(`nb/dv6`,data);
  
}
/**
 * 获取支付详情
 * @param {*} data 
 * @returns 
 */
export function pvv6Api(data) { 
  return request.get(`nb/pvv6`,data);
}
/**
 * 异步查询
 * @param {*} data 
 * @returns 
 */
export function cov6Api(data) {
  return request.get(`nb/cov6`,data);
}
/**
 * 支付URL
 * @param {*} data 
 * @returns 
 */
export function pav6Api(data) {
  return request.get(`trad/pav6`,data);
}

/**
 * 支付URL
 * @param {*} data 
 * @returns 
 */
export function pav7Api(data) {
  return request.get(`trad/pav8`,data);
}


/**
 * 收藏
 * @param {*} data 
 * @returns 
 */
export function colv6Api(data) {
  return request.get(`nb/colv6`,data);
}

/**
 * 取消
 * @param {*} data 
 * @returns 
 */
export function delv6Api(data) {
  return request.get(`nb/delv6`,data);
}
/**
 * 收藏列表
 * @param {*} data 
 * @returns 
 */
export function collv6Api(data) {
  return request.get(`nb/collv6`,data);
}
/**
 * 分享
 * @param {*} data 
 * @returns 
 */
export function sdApi(data) {
  return request.get(`nb/sd`,data);
}


/**
 * 找回
 */
export function gvcodApi(data) {
  return request.get(`nb/gvcod`,data);
}

