<template>
    <div class="poster-container">
      <canvas v-if="!showImg" ref="posterCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
      <img v-if="showImg" :src="posterImg" class="poster-img">
      
      <div class="btn-opr">
        <div class="box1"> 
          <div class="box-title1">短网址:{{permanentUrl}}</div>
          <div class="box-btn1" @click="handelClickCopy(permanentUrl)">复制地址</div>
        </div>
        <div class="box2"> 
          <div class="glow-button" @click="handelClickCopy(permanentUrl)">{{ text2 }}
            <div class="border-lines">
                <span class="line top"></span>
                <span class="line right"></span>
                <span class="line bottom"></span>
                <span class="line left"></span>
            </div>
          </div>
        </div>
        <div class="box3" style=""> 
          <div class="txt">长按保存图片到相册<span class="txt" v-if="number>0">({{ number }})</span></div>
          <div class="txt txt1" @click="closePoster">关闭</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import {sdApi} from '@/api/api';
  import QRCode from "qrcode";
  export default {
    props: {
      
    },
    data() {
      return {
        show:true,
        shareData:{},
        qrCodeData: null,
        tips:'网址:',
        tips2:'',
        highlightText:'收藏保存',
        highlightText1:'自动更新',
        imageUrl:'',
        permanentUrl:'',
        spreadUrl:'',
        qrCodeData: null,
        canvasWidth: 750,
        canvasHeight: 900,
        showImg:false,
        posterImg: '', // 海报图片的URL
        is_close:false,
        number:3,
        timer:null,
        is_weburl:0,
        text1:'',
        text2:'',
        adTipUrl:''

      };
    },
    created(){
        this.getData();
        this.time=setInterval(this.cuntDown,1000);
    },
    methods: {
      goWebUrl(){
        if(!this.isVX()){
              
            }else if (isAndroid() && isVX()){
                var d=encodeURIComponent(window.location.href);
                var url = this.adTipUrl + '?payurl=' + this.spreadUrl;
                var iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.src = url;
                document.body.appendChild(iframe);
            }
      },
      getShebeiType(){

      },
      isVX() {
          const ua = window.navigator.userAgent.toLowerCase()
          if (ua.match(/MicroMessenger/i) == 'micromessenger') {
              return true
          } else {
              return false
          }
      },
      isAndroid() {
          const ua = window.navigator.userAgent.toLowerCase();
          if (ua.match(/Android/i) == 'android') {
              return true;
          } else {
              return false;
          }
      },
      handelClickCopy(text){
        this.$copyText(text).then(e=>{
          this.$toast('复制成功');
        }).catch(e=>{
          this.$toast('复制失败');
        })
      },
      closePoster(){
      
        this.$emit('save-close');
      },
      cuntDown(){
        this.number--;
        if (this.number <= 0) {
          clearInterval(this.timer); // 清除定时器
          this.timer = null; // 清空定时器ID
        }
      },
        getData(){
          let that=this;
          let ldk=window.localStorage.getItem('ldk');
          sdApi({ldk:ldk}).then(res=>{
            let temp=JSON.parse(that.$decryptData(res));
           console.log(temp);
            that.shareData=temp;
            that.permanentUrl=temp.real_url;
            that.imageUrl=temp.backimg;
            that.spreadUrl=temp.spreadurl;
            that.is_weburl=temp.is_weburl;
            that.text2=temp.text2;
            that.adTipUrl=temp.adTipUrl;
            if(temp.is_weburl==0){
              this.tips='';
              this.permanentUrl='';
            }
             // 生成二维码
             that.generateQRCode();
          });
        },
        async generateQRCode() {
            let that=this;
            try {
            const qrCodeDataURL = await QRCode.toDataURL(that.spreadUrl, {
                width: 150,
                margin: 1,
            });
            that.qrCodeData = qrCodeDataURL;
          
           // 重新绘制海报
          
           if(that.is_weburl==0){
            setTimeout(() => {
                    that.drawPoster2(); 
                }, 1000); // 延迟500ms，确保数据加载完成
           }else{
            setTimeout(() => {
                    that.drawPoster(); 
                }, 1000); // 延迟500ms，确保数据加载完成
           }
               
            } catch (err) {
            console.error("二维码生成失败:", err);
            }
        },
        async drawPoster() {
            const canvas = this.$refs.posterCanvas;
            const ctx = canvas.getContext("2d");

            // 清空画布
            ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

            // 设置背景颜色
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

            // 绘制主图片
            await this.drawImage(ctx, this.imageUrl, 50, 50, this.canvasWidth - 100, 500);

            const tipsX = this.canvasWidth / 7; // 提示文字起始 X 坐标
            const tipsY = 640; // 提示文字起始 Y 坐标

            // 绘制红色高亮文字
            const highlightX = tipsX; // 红色高亮文字起始 X 坐标
            const highlightY = tipsY+100 ; // 红色高亮文字起始 Y 坐标
            ctx.fillStyle = "red";
            ctx.font = "bold 56px Arial";
            ctx.fillText(this.highlightText, highlightX, highlightY );

            ctx.fillStyle = "red";
            ctx.font = "bold 56px Arial";
            ctx.fillText(this.highlightText1, highlightX, highlightY +  80);
           

           

            // 绘制二维码
            const qrX = this.canvasWidth / 2 + 50; // 二维码起始 X 坐标
            const qrY = tipsY - 20; // 二维码起始 Y 坐标，与提示文字顶部对齐
            const qrSize = 260; // 二维码的大小

            if (this.qrCodeData) {
                const qrImage = new Image();
                qrImage.src = this.qrCodeData;
                await new Promise((resolve, reject) => {
                qrImage.onload = () => {
                    ctx.drawImage(qrImage, qrX, qrY, qrSize, qrSize);
                    resolve();
                };
                qrImage.onerror = reject;
                });
            }

            // 绘制
            if(this.tips && this.permanentUrl){
              const permanentText = this.tips;
              const urlText = this.permanentUrl;

              ctx.font = "bold 36px Arial";
              ctx.textAlign = "center";

              // 获取两段文字的宽度
              const permanentTextWidth = ctx.measureText(permanentText).width;
              const urlTextWidth = ctx.measureText(urlText).width;

              // 计算总宽度并居中显示
              const totalWidth = permanentTextWidth + urlTextWidth;
              const startX = (this.canvasWidth - totalWidth) / 2;

              // 绘制
              const permanentY = highlightY + 2 * 40 + 150;
              ctx.fillStyle = "#333";
              ctx.fillText(permanentText, (startX-50) + permanentTextWidth / 2, permanentY);

              // 绘制URL
              ctx.font = "bold 36px Arial";
              ctx.fillStyle = "red";
              ctx.fillText(urlText, startX + permanentTextWidth + urlTextWidth / 2+20, permanentY);
            }

            
            let that=this;
            setTimeout(function(){
              that.downloadPoster();
            },2000);
          },
          async drawPoster2() {
            const canvas = this.$refs.posterCanvas;
            const ctx = canvas.getContext("2d");

            // 清空画布
            ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

            // 设置背景颜色
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

            // 绘制主图片
            await this.drawImage(ctx, this.imageUrl, 50, 50, this.canvasWidth - 100, 500);

            const tipsX = this.canvasWidth / 7; // 提示文字起始 X 坐标
            const tipsY = 640; // 提示文字起始 Y 坐标

            // 绘制红色高亮文字
            const highlightX = tipsX; // 红色高亮文字起始 X 坐标
            const highlightY = tipsY+100 ; // 红色高亮文字起始 Y 坐标
            ctx.fillStyle = "red";
            ctx.font = "bold 56px Arial";
            ctx.fillText(this.highlightText, highlightX, highlightY );

            ctx.fillStyle = "red";
            ctx.font = "bold 56px Arial";
            ctx.fillText(this.highlightText1, highlightX, highlightY +  80);
           

           

            // 绘制二维码
            const qrX = this.canvasWidth / 2 + 50; // 二维码起始 X 坐标
            const qrY = tipsY - 20; // 二维码起始 Y 坐标，与提示文字顶部对齐
            const qrSize = 260; // 二维码的大小

            if (this.qrCodeData) {
                const qrImage = new Image();
                qrImage.src = this.qrCodeData;
                await new Promise((resolve, reject) => {
                qrImage.onload = () => {
                    ctx.drawImage(qrImage, qrX, qrY, qrSize, qrSize);
                    resolve();
                };
                qrImage.onerror = reject;
                });
            }

            // 绘制
            // const permanentText = "";
            // const urlText = this.permanentUrl;

            // ctx.font = "bold 30px Arial";
            // ctx.textAlign = "center";

            // // 获取两段文字的宽度
            // const permanentTextWidth = ctx.measureText(permanentText).width;
            // const urlTextWidth = ctx.measureText(urlText).width;

            // // 计算总宽度并居中显示
            // const totalWidth = permanentTextWidth + urlTextWidth;
            // const startX = (this.canvasWidth - totalWidth) / 2;

            // // 绘制
            // const permanentY = highlightY + 2 * 40 + 210;
            // ctx.fillStyle = "#333";
            // ctx.fillText(permanentText, (startX-50) + permanentTextWidth / 2, permanentY);

            // // 绘制URL
            // ctx.font = "bold 36px Arial";
            // ctx.fillStyle = "red";
            // ctx.fillText(urlText, startX + permanentTextWidth + urlTextWidth / 2+20, permanentY);
            let that=this;
            setTimeout(function(){
              that.downloadPoster();
            },2000);
          },
      drawImage(ctx, src, x, y, width, height) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "anonymous"; // 跨域处理
          img.src = src;
          img.onload = () => {
            ctx.drawImage(img, x, y, width, height);
            resolve();
          };
          img.onerror = reject;
        });
      },
     
      downloadPoster() {
        const canvas = this.$refs.posterCanvas;
        if(canvas){
            this.showImg=true;
            this.posterImg = canvas.toDataURL("image/png");
            this.is_close=true;
        }
        this.showImg=true;
        this.$toast('长按保存图片');
       
      },
     
    },
    mounted() {
      this.drawPoster();
    },
    watch: {
      imageUrl: "drawPoster",
      tips: "drawPoster",
      highlightText: "drawPoster",
      permanentUrl: "drawPoster",
    },
  };
  </script>
  
  <style scoped lang="scss">
  .poster-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    // background-color: #DB40CA; 
     background: #fff;

  }
  canvas {
    border: 1px solid #fff;
    max-width: 100%;
    height: auto;
  }
  .download-btn {
    margin-top: 10px;
    padding: 10px 20px;
    background-image: linear-gradient(#fffaaa, #fff000) !important;
    border-radius: 5px;
    color: #da2b30;
    font-weight: 600;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }
  .download-btn:hover {
    background: #0056b3;
  }
  .poster-img{
    width: 100%;
  }
  .btn-opr{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: rgb(255, 255, 255);
    width: 100%;
    /* height: 60px; */
    /* line-height: 60px; */
    align-items: center;
    width: 100%;

    .box1{
      display: flex;
      justify-content: space-around;
      align-items: center;
      .box-title1{
        // color:red ;
        font-size: 16px;
        font-weight: 600;
      }
      .box-btn1{
        font-size: 14px !important;
        color:#F50304 !important;
        border-radius: 5px;
        background:  linear-gradient(#FCF518, #F3B501);
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        border-radius: 50px;
        padding: 0 8px;
        margin-left: 20px;
      }
    }
   .box2{
    position: relative;
   
      .box-btn2{
        height: 40px;
        line-height: 40px;
        width: 320px;
        //background: #FCF518;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 700;
        border-radius: 8px;
        margin-top: 10px;
       color: red;
      }
      .box-title2{
        color:red !important;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px 0;
      }
   }
   .box3{
     display: flex;align-items: center;
      width: 320px;
        background: #353E4B;
        justify-content: space-around;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
        margin-top: 20px;
    .txt{
        color:#ffffff;
    }
    .txt1{
      padding:0px 15px;
    }
   }
  }
  

  .glow-button {
    margin-top:20px;
    position: relative;
    width: 300px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background: transparent;
    color: red;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 0 1px red;
    transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
}

.glow-button:hover {
    background-color: red;
    color: #fff;
    box-shadow: 0 0 5px red, 0 0 25px red, 0 0 50px red, 0 0 100px red;
}

.glow-button:active {
    background-color: red;
    color: #fff;
    box-shadow: 0 0 15px red, 0 0 30px red, 0 0 60px red, 0 0 120px red;
    transform: scale(0.95);
}

.border-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.line {
    position: absolute;
    display: block;
    background: linear-gradient(90deg, transparent, red);
}

.top {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    animation: topAnim 4s linear infinite;
}

@keyframes topAnim {
    0% {
        left: -100%;
    }
    50%,
    100% {
        left: 100%;
    }
}

.right {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    animation: rightAnim 4s linear infinite 1s;
    background: linear-gradient(180deg, transparent, red);
}

@keyframes rightAnim {
    0% {
        top: -100%;
    }
    50%,
    100% {
        top: 100%;
    }
}

.bottom {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    animation: bottomAnim 4s linear infinite 2s;
    background: linear-gradient(270deg, transparent, red);
}

@keyframes bottomAnim {
    0% {
        right: -100%;
    }
    50%,
    100% {
        right: 100%;
    }
}

.left {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    animation: leftAnim 4s linear infinite 3s;
    background: linear-gradient(360deg, transparent, red);
}

@keyframes leftAnim {
    0% {
        bottom: -100%;
    }
    50%,
    100% {
        bottom: 100%;
    }
}

  </style>
  