
import Cookies from "js-cookie";
// 请求接口地址 如果没有配置自动获取当前网址路径
const VUE_APP_API_URL = ''
const login_title =  ''


const SettingMer = {
  // 服务器地址
  httpUrl: VUE_APP_API_URL,
  // 接口请求地址
  https: VUE_APP_API_URL + '/api/',
  // 路由标题
  title: login_title || '加载中...'
}

export default SettingMer
